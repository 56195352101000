<template>
    <modal-lateral ref="modalFiltroAfiliacion" titulo="Filtro">
        <div class="custom-scroll overflow-auto" style="height:calc(100vh - 150px)">
            <p class="col-12 text-general f-14 mt-2 mb-3">
                Tipo de Afiliado
            </p>
            <div class="row mx-5 ml-4 mb-2">
                <el-checkbox v-model="activo" class="check-dark" />
                <i class="icon-account-check f-18 mx-2 text-green" />
                <div class="col f-16">
                    Afiliado activo
                </div>
            </div>
            <div class="row mx-5 ml-4 mb-2">
                <el-checkbox v-model="desvinculado" class="check-dark" />
                <i class="icon-account-remove-outline f-18 mx-2 text-general-red" />
                <div class="col f-16">
                    Desvinculado con deuda
                </div>
            </div>
            <div class="row mx-5 ml-4 mb-2">
                <el-checkbox v-model="solicitud" class="check-dark" />
                <i class="icon-account-wait f-18 mx-2 text-orange" />
                <div class="col f-16">
                    Solicitud de afiliación
                </div>
            </div>
            <div class="row mx-5">
                <div class="col-12 px-0">
                    <p class="text-general f-12 mt-4 pl-3">
                        Rango de fecha de compras totales
                    </p>
                    <el-date-picker
                    v-model="fechas"
                    type="daterange"
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                    size="small"
                    class="w-100"
                    :clearable="false"
                    range-separator="a"
                    start-placeholder="Inicio"
                    end-placeholder="Fin"
                    />
                </div>
            </div>
            <p class="col-12 text-general f-14 mt-4 mb-3">
                Mostrar las compras totales
            </p>
            <div class="row mx-5 ml-4 mb-2">
                <el-checkbox v-model="nomina" class="check-dark" />
                <div class="col f-16">
                    Descontado de nómina
                </div>
            </div>
            <div class="row mx-5 ml-4 mb-2">
                <el-checkbox v-model="contado" class="check-dark" />
                <div class="col f-16">
                    Pago de contado
                </div>
            </div>
        </div>
        <div class="row mx-0 justify-center">
            <!-- LO COMENTE POR QUE EL FILTRO NO PUEDE QUEDAR LIMPIO -->
            <!-- <div class="col-12 mb-2 mx-0 bg-linea" /> -->
            <!-- <div class="btn-outline f-14 px-3 mr-2">
                Limpiar
            </div> -->
            <p v-show="!filtroValido" class="text-danger f-13 mx-auto mb-3">Por favor seleccione almenos un tipo de afiliado y un tipo de pago</p>
            <div class="btn-general f-14 px-5 ml-2" :class="{'disabled-div':!filtroValido}" @click="filtrar">
                Filtrar
            </div>
        </div>
    </modal-lateral>
</template>

<script>
import moment from 'moment'
export default {
    data(){
        return {
            activo: true,
            desvinculado:true,
            solicitud:true,
            nomina:true,
            contado:true,
            fechas:[]
        }
    },
    computed:{
        filtroValido(){
            if(
                (this.activo ||
                this.desvinculado ||
                this.solicitud) &&
                (this.nomina ||
                this.contado)
            ){
                return true
            }else{
                return false
            }
        }
    },
    methods: {
        toggle(){
            if(this.fechas.length == 0){
                const hoy = moment().format('YYYY-MM-DD')
                const menos30 = moment().subtract(30,'days').format('YYYY-MM-DD')

                this.fechas.push(menos30,hoy)
            }
            this.$refs.modalFiltroAfiliacion.toggle();
        },
        filtrar(){
            if(!this.filtroValido) return
            const filtro = {
                activo: Number(this.activo),
                desvinculado: Number(this.desvinculado),
                solicitud: Number(this.solicitud),
                nomina: Number(this.nomina),
                contado: Number(this.contado),
                fechas: this.fechas
            }

            this.$emit('filtrar',filtro)
            this.$refs.modalFiltroAfiliacion.toggle();
        }

    }
}
</script>
